<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-10/12 mx-auto" v-if="!isLoading">
      <button
        type="button"
        class="bg-green-1000 text-white px-6 py-3 rounded rounded-md border border-gray-400 focus:outline-none"
        @click="goBack"
      >
        <svg
          style="display: initial;"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
        Voltar
      </button>
      <div class="p-4 flex">
        <h1 class="text-3xl">
          Entrevista
        </h1>
      </div>
      <form-wizard
        @on-complete="onComplete"
        :start-index="0"
        color="#69CA5E"
        title=""
        subtitle=""
        ref="form"
        next-button-text="Próximo"
        back-button-text="Anterior"
        :finish-button-text="this.returnFinishButtonText()"
      >
        <tab-content title="Entrevista" icon="ri-seedling-line">
          <div
            class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
          >
            <div class="flex w-full mb-5">
              <div class="flex w-full flex-col mr-5">
                <label class="mb-2" for="office">Nome</label>
                <input
                  v-model="$v.interview.name.$model"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none cursor-not-allowed"
                  id="nome"
                  type="text"
                  placeholder="Nome entrevistado"
                  autocomplete="off"
                  disabled
                />
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="validity_from">Data Entrevista</label>
                <input
                  v-model="$v.interview.date_interview.$model"
                  class="appearance-none block w-full text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="validity_from"
                  type="date"
                  placeholder=""
                  autocomplete="off"
                  :disabled="tipo != 'incluir'"
                  :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                />
                <div v-if="$v.interview.date_interview.$error">
                  <div
                    class="error"
                    v-if="!$v.interview.date_interview.required"
                  >
                    Campo obrigatório.
                  </div>
                </div>
              </div>
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="hours">Horário</label>
                <input
                  v-model="$v.interview.hours_interview.$model"
                  class="appearance-none block w-full text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="hours"
                  type="time"
                  placeholder="9h às 18h"
                  autocomplete="off"
                  :disabled="tipo != 'incluir'"
                  :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                />
                <div v-if="$v.interview.hours_interview.$error">
                  <div
                    class="error"
                    v-if="!$v.interview.hours_interview.required"
                  >
                    Campo obrigatório.
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-full flex-row items-center mr-5">
                <input
                  v-model="interview_online"
                  type="checkbox"
                  name="interview_online"
                  class="mr-2"
                  :disabled="tipo != 'incluir'"
                  :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                />
                <label for="interview_online">Entrevista Online?</label>
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-full flex-col mr-5" v-if="interview_online">
                <input
                  v-model="interview.link_interview_online"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="link_interview_online"
                  type="text"
                  placeholder="Exemplo: https://meet.google.com/link-para-entrevista-online"
                  autocomplete="off"
                  :disabled="tipo != 'incluir'"
                  :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                />
              </div>
            </div>
            <div v-if="!interview_online">
              <div class="flex w-full mb-5">
                <div class="flex w-3/12 flex-col mr-5">
                  <label class="mb-2" for="cep">CEP</label>
                  <input
                    v-model="$v.interview.zip_code.$model"
                    id="cep"
                    type="text"
                    class="appearance-none block w-full text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    placeholder="CEP"
                    v-mask="'#####-###'"
                    :disabled="tipo != 'incluir'"
                    :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                  />
                  <div v-if="$v.interview.zip_code.$error">
                    <div class="error" v-if="!$v.interview.zip_code.required">
                      Campo obrigatório.
                    </div>
                  </div>
                </div>

                <div class="flex w-7/12 flex-col mr-5">
                  <label class="mb-2" for="address">Endereço</label>
                  <input
                    v-model="$v.interview.address.$model"
                    id="address"
                    type="text"
                    class="appearance-none block w-full text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    placeholder="Endereço"
                    :disabled="tipo != 'incluir'"
                    :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                  />
                  <div v-if="$v.interview.address.$error">
                    <div class="error" v-if="!$v.interview.address.required">
                      Campo obrigatório.
                    </div>
                  </div>
                </div>

                <div class="flex w-2/12 flex-col mr-5">
                  <label class="mb-2" for="number">Número</label>
                  <input
                    v-model="interview.number"
                    id="number"
                    type="text"
                    class="appearance-none block w-full text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    placeholder="Número"
                    :disabled="tipo != 'incluir'"
                    :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                  />
                </div>
              </div>
              <div class="flex w-full mb-5">
                <div class="flex w-6/12 flex-col mr-5">
                  <label class="mb-2" for="complement">Complemento</label>
                  <input
                    v-model="interview.complement"
                    id="complement"
                    type="text"
                    class="appearance-none block w-full text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    placeholder="Complemento"
                    :disabled="tipo != 'incluir'"
                    :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                  />
                  <div v-if="$v.interview.complement.$error">
                    <div class="error" v-if="!$v.interview.complement.required">
                      Campo obrigatório.
                    </div>
                  </div>
                </div>

                <div class="flex w-6/12 flex-col mr-5">
                  <label class="mb-2" for="neighborhood">Bairro</label>
                  <input
                    v-model="$v.interview.neighborhood.$model"
                    id="neighborhood"
                    type="text"
                    class="appearance-none block w-full text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    placeholder="Bairro"
                    :disabled="tipo != 'incluir'"
                    :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                  />
                  <div v-if="$v.interview.neighborhood.$error">
                    <div
                      class="error"
                      v-if="!$v.interview.neighborhood.required"
                    >
                      Campo obrigatório.
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex w-full mb-5">
                <div class="flex w-6/12 flex-col mr-5">
                  <label class="mb-2" for="state">Estado</label>
                  <multiselect
                    id="state"
                    v-model="address.selected_state"
                    deselect-label="Clique para remover"
                    track-by="name"
                    label="name"
                    select-label="Clique para selecionar"
                    selected-label="Selecionado"
                    placeholder="Selecione um estado"
                    :options="address.states"
                    @select="fetchCities"
                    :disabled="tipo != 'incluir'"
                    :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.name }}
                    </template>
                    <span slot="noOptions">Nenhum estado encontrado.</span>
                  </multiselect>
                </div>

                <div class="flex w-6/12 flex-col mr-5">
                  <label class="mb-2" for="city">Cidade</label>
                  <multiselect
                    id="city"
                    v-model="address.selected_city"
                    deselect-label="Clique para remover"
                    track-by="name"
                    label="name"
                    select-label="Clique para selecionar"
                    selected-label="Selecionado"
                    placeholder="Selecione uma cidade"
                    :options="address.cities"
                    :disabled="tipo != 'incluir'"
                    :class="tipo != 'incluir' ? 'bg-gray-200' : 'bg-white'"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.name }}
                    </template>
                    <span slot="noOptions">Nenhuma cidade encontrada.</span>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="flex w-full mb-5" v-if="tipo != 'incluir'">
              <div class="flex w-6/12 flex-col mr-5">
                <span class="mb-2" for="checked">
                  <input
                    v-model="interview.was_interviewed"
                    type="checkbox"
                    id="checked"
                    :disabled="tipo != 'atualizar'"
                    :class="tipo != 'atualizar' ? 'bg-gray-200' : 'bg-white'"
                  />
                  Foi entrevistado?
                  <div v-if="$v.interview.was_interviewed.$error">
                    <div
                      class="error"
                      v-if="!$v.interview.was_interviewed.required"
                    >
                      Campo obrigatório.
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div class="flex w-full mb-5" v-if="tipo != 'incluir'">
              <div class="flex w-full flex-col mr-5">
                <label class="mb-2" for="office"
                  >Considerações da entrevista</label
                >
                <textarea
                  v-model="interview.considerations"
                  rows="4"
                  class="appearance-none block w-full text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="consideracoesEntrevista"
                  type="text"
                  placeholder="Considerações da entrevista"
                  autocomplete="off"
                  :disabled="
                    tipo == 'visualizar' ||
                      (!interview.was_interviewed && tipo != 'incluir')
                  "
                  :class="
                    tipo == 'visualizar' ||
                    (!interview.was_interviewed && tipo != 'incluir')
                      ? 'bg-gray-200'
                      : 'bg-white'
                  "
                />
              </div>
            </div>

            <div class="flex w-full mb-5">
              <div class="flex w-full flex-col mr-5">
                <label class="mb-2" for="office">Histórico de Contato </label>
                <textarea
                  v-model="interview.historic_contact"
                  rows="4"
                  class="appearance-none block w-full text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="contactHistory"
                  type="text"
                  placeholder="Histórico de Contato "
                  autocomplete="off"
                  :disabled="tipo == 'visualizar'"
                  :class="tipo == 'visualizar' ? 'bg-gray-200' : 'bg-white'"
                />
                <div v-if="$v.interview.historic_contact.$error">
                  <div
                    class="error"
                    v-if="!$v.interview.historic_contact.required"
                  >
                    Campo obrigatório.
                  </div>
                </div>
              </div>
            </div>

            <fieldset
              class="min-h-full max-h-96 overflow-auto rounded-lg border border-gray-600 py-2 px-3 mb-3"
            >
              <legend>Log de contato</legend>
              <div
                class="flex w-full md:flex-wrap md:flex-nowrap mb-2"
                v-for="log in logs"
                :key="log.data"
              >
                <div class="flex w-full md:w-4/12 flex-col mr-5">
                  <label class="mb-2 text-sm" for="log_date">Data</label>
                  <input
                    :value="log.data"
                    type="text"
                    id="log_date"
                    disabled
                    class="text-xs appearance-none block w-full text-gray-700 border border-gray-600 bg-gray-200 rounded-lg py-1 px-3 mb-2 leading-tight focus:outline-none focus:bg-white"
                  />
                </div>
                <div class="flex w-full md:w-5/12 flex-col mr-5">
                  <label class="mb-2 text-sm" for="log_user">Usuário</label>
                  <input
                    :value="log.user"
                    type="text"
                    id="log_user"
                    disabled
                    class="text-xs appearance-none block w-full text-gray-700 border border-gray-600 bg-gray-200 rounded-lg py-1 px-3 mb-1 leading-tight focus:outline-none focus:bg-white"
                  />
                </div>
                <div class="flex w-full flex-col mr-5">
                  <label class="mb-2 text-sm" for="log_history"
                    >Histórico</label
                  >
                  <textarea
                    v-model="log.message"
                    id="log_history"
                    disabled
                    class="text-sm appearance-none block w-full text-gray-700 border border-gray-600 bg-gray-200 rounded-lg py-1 px-3 mb-1 leading-tight focus:outline-none focus:bg-white"
                  >
                  </textarea>
                </div>
              </div>
            </fieldset>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout';
import { mask } from 'vue-the-mask';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'Interview',

  title() {
    return `${process.env.VUE_APP_NAME} | Entrevista`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  directives: { mask },

  props: {
    tipo: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean
    },
    vacancy: {
      type: String,
      required: true
    },
    participant: {
      type: String,
      required: true
    },
    id: {
      type: String
    }
  },

  data() {
    return {
      isLoading: false,
      interview_online: false,
      logs: [],
      interview: {
        name: '',
        date_interview: '',
        hours_interview: '',
        link_interview_online: '',
        zip_code: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        state: '',
        city: '',
        was_interviewed: false,
        historic_contact: '',
        considerations: '',
        participant_id: '',
        start_activities: '',
        vacancy_id: ''
      },
      address: {
        states: [],
        selected_state: null,
        cities: [],
        selected_city: null
      }
    };
  },

  validations: {
    interview: {
      name: { required },
      date_interview: { required },
      hours_interview: { required },
      zip_code: { required },
      address: { required },
      neighborhood: { required },
      complement: { required },
      was_interviewed: { required },
      historic_contact: { required }
    }
  },
  computed: {
    ...mapGetters('auth', {
      authUser: 'user',
      authAssociate: 'associate',
      authSyndicate: 'syndicate'
    })
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    fetchNameParticipant() {
      this.isLoading = true;
      axios
        .get(`api/participantes/${this.participant}/usuario`)
        .then(({ data }) => {
          this.interview.name = data.data.name;
          this.isLoading = false;
        });
    },

    returnFinishButtonText() {
      var finishButtonText;

      if (this.tipo == 'incluir') {
        finishButtonText = 'Concluir';
      } else if (this.tipo == 'visualizar') {
        finishButtonText = 'Voltar';
        //this.$router.push({name: 'list-vacancies'});
      } else if (this.tipo == 'atualizar') {
        finishButtonText = 'Salvar';
      }

      return finishButtonText;
    },
    onComplete() {
      let scheduleInterview = {};

      if (!this.interview_online && this.$v.interview.$invalid) {
        this.$v.interview.date_interview.$touch();
        this.$v.interview.hours_interview.$touch();
        this.$v.interview.zip_code.$touch();
        this.$v.interview.address.$touch();
        this.$v.interview.neighborhood.$touch();
        this.$v.interview.complement.$touch();
        this.$v.interview.historic_contact.$touch();
      } else {
        let state = '';
        let city = '';
        if (!this.interview.link_interview_online) {
          state = this.address.selected_state.id;
          city = this.address.selected_city.id;
        }

        scheduleInterview = {
          user: this.authUser.id,
          name: this.interview.name,
          date_interview: this.interview.date_interview,
          hours_interview: this.interview.hours_interview,
          link_interview_online: this.interview.link_interview_online,
          zip_code: this.interview.zip_code,
          address: this.interview.address,
          number: this.interview.number,
          complement: this.interview.complement,
          neighborhood: this.interview.neighborhood,
          state: state,
          city: city,
          was_interviewed: this.interview.was_interviewed,
          historic_contact: this.interview.historic_contact,
          considerations: this.interview.considerations,
          participant_id: this.participant,
          vacancy_id: this.vacancy
        };
      }

      if (this.tipo == 'incluir') {
        this.$confirm({
          message: 'Deseja realmente confirmar o agendamento? ',
          button: {
            no: 'Não',
            yes: 'Sim'
          },
          callback: confirm => {
            if (confirm) {
              axios
                .post(
                  'api/participante/incluir-formulario-agendamento',
                  scheduleInterview
                )
                .then(({ data }) => {
                  this.$toast.success(data.data.message);
                })
                .then(() => {
                  this.$router.push({
                    name: 'schedule-interview',
                    params: { id: this.vacancy }
                  });
                });
            }
          }
        });
      } else if (this.tipo == 'atualizar') {
        console.log(scheduleInterview);
        axios
          .put(`api/participante/${this.id}/update`, scheduleInterview)
          .then(({ data }) => {
            this.$toast.success(data.data.message);
          })
          .then(() => {
            this.$router.push({
              name: 'schedule-interview',
              params: { id: this.vacancy }
            });
          })
          .catch(({ response }) => {
            //
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      } else if (this.tipo == 'visualizar') {
        this.$router.push({
          // name: 'schedule-interview',
          // params: { id: this.vacancy }
          name: 'list-vacancies'
        });
      }
    },

    fetchParticipant() {
      this.isLoading = true;
      axios
        .get(
          `api/participante/edit-form-scheduling/${this.vacancy}/${this.participant}`
        )
        .then(({ data }) => {
          data.data.forEach(participant => {
            console.log(participant);
            this.id = participant.id;
            this.interview.name = participant.name;
            this.interview.date_interview = participant.date_interview;
            this.interview.hours_interview = participant.hours_interview;
            this.interview.link_interview_online =
              participant.link_interview_online;
            this.interview.zip_code = participant.zip_code;
            this.interview.address = participant.address;
            this.interview.number = participant.number;
            this.interview.complement = participant.complement;
            this.interview.neighborhood = participant.neighborhood;
            this.address.selected_state = participant.state;
            this.address.selected_city = participant.city;
            this.interview.was_interviewed = participant.was_interviewed;
            this.interview.considerations = participant.considerations;

            if (this.interview.link_interview_online != null)
              this.interview_online = true;
          });
          this.isLoading = false;
        });
    },

    async fetchVacancy() {
      this.isLoading = true;
      const response = await axios.get(`api/vagas/${this.vacancy}/editar`);
      response.data.vacancy.forEach(teste => {
        this.interview.zip_code = teste.cep;
        this.interview.address = teste.address;
        this.interview.number = teste.number;
        this.interview.complement = teste.complement;
        this.interview.neighborhood = teste.neighborhood;
        this.address.selected_state = teste.state;
        this.address.selected_city = teste.city;
        this.isLoading = false;
      });
    },

    fetchLog() {
      this.isLoading = true;
      //const user = this.authUser.id;
      axios
        .get(`api/participante/log/${this.vacancy}/${this.participant}`)
        .then(({ data }) => {
          data.logs.forEach(log => {
            this.logs.push({
              data: log.created_at,
              user: log.name,
              message: log.log
            });
          });
          this.isLoading = false;
        });
    },

    async fetchStates() {
      await axios.get('/api/estados').then(({ data }) => {
        data.data.forEach(state =>
          this.address.states.push({
            id: state.id,
            name: state.name
          })
        );
      });
    },

    async fetchCities(state) {
      this.address.cities = [];
      this.address.selected_city = null;

      await axios.get(`/api/estados/${state.id}/cidades`).then(({ data }) => {
        data.data.forEach(city =>
          this.address.cities.push({
            id: city.id,
            name: city.name
          })
        );
      });
    }
  },
  created() {
    this.fetchVacancy();
    this.fetchStates();
    this.fetchNameParticipant();
    this.fetchParticipant();
    this.fetchLog();
  }
};
</script>

<style scoped>
.error {
  color: red;
}
textarea {
  resize: none;
}
</style>
